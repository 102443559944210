<template>
  <div v-show="user.canReview">
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #departmentId="item">
          <b-form-group :label-for="item.field.key">
            <label>
              {{ $t(item.field.label) }}
            </label>
            <n-async-single-select
              v-model="params[item.field.key]"
              :query="{
                limit: 0,
                company_id: params.companyId,
              }"
              :ref="item.field.key"
              :name="item.field.key"
              :repository="item.field.repository"
              :selection-key="item.field.selectionKey"
              :selection-label="item.field.selectionLabel"
            ></n-async-single-select>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>

      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :deletable="true"
          :resource="resource"
          :route="route"
        >
          <template #cell(profile)="data">
            <b-img
              rounded="circle"
              v-if="data.item.employee.profile != ''"
              :src="data.item.employee.profile"
              width="60"
              height="60"
            >
            </b-img>
            <b-img
              rounded="circle"
              v-else
              src="@/assets/images/avatars/no_image.jpg"
              width="60"
              height="60"
            >
            </b-img>
          </template>

          <template #cell(name)="data">
            <span v-if="data.item.employee">
              {{ data.item.employee.name }}
            </span>
          </template>
          <template #cell(idCard)="data">
            <span v-if="data.item.employee">
              {{ data.item.employee.idCard }}
            </span>
          </template>
          <template #cell(company)="data">
            <span v-if="data.item.employee.company.name">
              {{ data.item.employee.company.name }}
            </span>
          </template>
          <template #cell(department)="data">
            <span v-if="data.item.employee.department">
              {{ data.item.employee.department.name }}
            </span>
          </template>
          <template #cell(position)="data">
            <span v-if="data.item.employee.position">
              {{ data.item.employee.position.name }}
            </span>
          </template>
          <template #cell(year)="data">
            <span v-if="data.item">
              {{ data.item.year }}
            </span>
          </template>
          <template #cell(state)="data">
            <b-badge
              pill
              :variant="getState(data.item.state).color"
              badge-glow
              v-if="data.item.state"
            >
              {{ $t(getState(data.item.state).name) }}
            </b-badge>
            <feather-icon
              v-show="data.item.state == State.HODREJECTED"
              v-b-modal="`okr-hod-reject-modal-${data.item.id}`"
              class="ml-1"
              icon="InfoIcon"
            ></feather-icon>
            <feather-icon
              v-show="data.item.state == State.HRREJECTED"
              v-b-modal="`okr-hr-reject-modal-${data.item.id}`"
              class="ml-1"
              icon="InfoIcon"
            ></feather-icon>
          </template>
          <template #cell(midYearState)="data">
            <b-badge
              v-if="data.item.midYearReview != null"
              pill
              :variant="getState(data.item.midYearReview.state).color"
              badge-glow
            >
              {{ $t(getState(data.item.midYearReview.state).name) }}
            </b-badge>
            <b-badge v-else pill :variant="getState('none').color" badge-glow>
              {{ $t(getState("none").name) }}
            </b-badge>
            <feather-icon
              v-if="data.item.midYearReview != null"
              v-show="data.item.midYearReview.state == State.HODREJECTED"
              v-b-modal="`mid-year-hod-reject-modal-${data.item.id}`"
              class="ml-1"
              icon="InfoIcon"
            ></feather-icon>
            <feather-icon
              v-if="data.item.midYearReview != null"
              v-show="data.item.midYearReview.state == State.HRREJECTED"
              v-b-modal="`mid-year-hr-reject-modal-${data.item.id}`"
              class="ml-1"
              icon="InfoIcon"
            ></feather-icon>
          </template>
          <template #cell(yearEndState)="data">
            <b-badge
              v-if="data.item.yearEndReview != null"
              pill
              :variant="getState(data.item.yearEndReview.state).color"
              badge-glow
            >
              {{ $t(getState(data.item.yearEndReview.state).name) }}
            </b-badge>

            <b-badge v-else pill :variant="getState('none').color" badge-glow>
              {{ $t(getState("none").name) }}
            </b-badge>
            <feather-icon
              v-if="data.item.yearEndReview != null"
              v-show="data.item.yearEndReview.state == State.HODREJECTED"
              v-b-modal="`year-end-hod-reject-modal-${data.item.id}`"
              class="ml-1"
              icon="InfoIcon"
            ></feather-icon>
            <feather-icon
              v-if="data.item.yearEndReview != null"
              v-show="data.item.yearEndReview.state == State.HRREJECTED"
              v-b-modal="`year-end-hr-reject-modal-${data.item.id}`"
              class="ml-1"
              icon="InfoIcon"
            ></feather-icon>

            <!-- OKR REJECT MODAL -->
            <div>
              <reject-modal
                v-if="data.item.hodRejectRemark != null"
                :modalId="`okr-hod-reject-modal-${data.item.id}`"
                :description="data.item.hodRejectRemark"
              ></reject-modal>
              <reject-modal
                v-if="data.item.hrRejectRemark != null"
                :modalId="`okr-hr-reject-modal-${data.item.id}`"
                :description="data.item.hrRejectRemark"
              ></reject-modal>
            </div>
            <!-- MID YEARS REJECT MODAL -->
            <div v-if="data.item.midYearReview != null">
              <reject-modal
                v-if="data.item.midYearReview.hodRejectRemark != null"
                :modalId="`mid-year-hod-reject-modal-${data.item.id}`"
                :description="data.item.midYearReview.hodRejectRemark"
              ></reject-modal>
              <reject-modal
                v-if="data.item.midYearReview.hrRejectRemark != null"
                :modalId="`mid-year-hr-reject-modal-${data.item.id}`"
                :description="data.item.midYearReview.hrRejectRemark"
              ></reject-modal>
            </div>

            <!-- YEAR END REJECT MODAL -->
            <div v-if="data.item.yearEndReview != null">
              <reject-modal
                v-if="data.item.yearEndReview.hodRejectRemark != null"
                :modalId="`year-end-hod-reject-modal-${data.item.id}`"
                :description="data.item.yearEndReview.hodRejectRemark"
              ></reject-modal>

              <reject-modal
                v-if="data.item.yearEndReview.hrRejectRemark != null"
                :modalId="`year-end-hr-reject-modal-${data.item.id}`"
                :description="data.item.yearEndReview.hrRejectRemark"
              ></reject-modal>
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <!-- Supervisor -->
              <template v-if="user.canReview && !user.canReviewFinal && !user.canApprove">
                <b-button
                  class="btn btn-sm"
                  variant="info"
                  :to="{
                    name: 'supervisor-review',
                    params: {
                      id: data.item.id,
                    },
                  }"
                >
                  <feather-icon icon="EditIcon"></feather-icon>
                  {{ $t("button.review") }}
                </b-button>
              </template>
              <!-- HOD -->
              <template v-if="user.canReview && user.canReviewFinal && !user.canApprove">
                <b-button
                  v-if="user.id == data.item.employee.managerId"
                  class="btn btn-sm"
                  variant="info"
                  :to="{
                    name: 'supervisor-review',
                    params: {
                      id: data.item.id,
                    },
                  }"
                >
                  <feather-icon icon="EditIcon"></feather-icon>
                  {{ $t("button.review") }}
                </b-button>
                <b-button
                  v-else
                  class="btn btn-sm"
                  variant="info"
                  :to="{
                    name: 'hod-review',
                    params: {
                      id: data.item.id,
                    },
                  }"
                >
                  <feather-icon icon="EditIcon"></feather-icon>
                  {{ $t("button.review") }}
                </b-button>
              </template>
              <!-- GM -->
              <template v-if="user.canReview && user.canReviewFinal && user.canApprove">
                <b-button
                  class="btn btn-sm"
                  variant="primary"
                  :to="{
                    name: 'gm-review',
                    params: {
                      id: data.item.id,
                    },
                  }"
                >
                  <feather-icon icon="CheckCircleIcon"></feather-icon>
                  {{ $t("button.verify") }}
                </b-button>
              </template>

              <!-- Old -->
              <!-- <b-button
                v-show="
                  user.id == data.item.employee.managerId && user.canReview
                "
                class="btn btn-sm"
                :to="{
                  name: 'supervisor-review',
                  params: {
                    id: data.item.id,
                  },
                }"
                variant="primary"
              >
                <div v-if="data.item.yearEndReview != null">
                  <feather-icon
                    v-if="data.item.yearEndReview.state != State.HODAPPROVED || 
                      data.item.yearEndReview.state != State.GMAPPROVED"
                    icon="EditIcon"
                  ></feather-icon>
                  <feather-icon v-else icon="EyeIcon"></feather-icon>
                  {{
                    data.item.yearEndReview.state != State.HODAPPROVED || 
                    data.item.yearEndReview.state != State.GMAPPROVED
                      ? $t("button.review")
                      : $t("button.view")
                  }}
                </div>
                <div v-else>
                  <feather-icon icon="EditIcon"></feather-icon>
                  {{ $t("button.review") }}
                </div>
              </b-button>
              <b-button
                class="btn btn-sm"
                v-show="user.canReviewFinal"
                variant="success"
                :to="{
                  name: 'hod-review',
                  params: {
                    id: data.item.id,
                  },
                }"
              >
                <feather-icon icon="Edit3Icon"></feather-icon>
                {{ $t("button.finalReview") }}
              </b-button>
              <b-button
                class="btn btn-sm"
                v-show="user.canApprove"
                variant="primary"
                :to="{
                  name: 'gm-review',
                  params: {
                    id: data.item.id,
                  },
                }"
              >
                <feather-icon icon="CheckCircleIcon"></feather-icon>
                {{ $t("button.verify") }}
              </b-button> -->
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import NPagination from "@/components/NPagination";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import { avatarText } from "@core/utils/filter";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import { getState, State } from "@/libs/helper";
import Repository from "@/repositories/RepositoryFactory";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import NSearchContainer from "@/components/NSearchContainer.vue";
import NSearchInput from "@/components/NSearchInput.vue";
import RejectModal from "@/components/RejectModal.vue";
import SearchInputs from "./searchInput";
const OkrSettingRepository = Repository.get("okrSetting");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BFormGroup,
    BImg,

    NPagination,
    NTable,
    vSelect,
    NAsyncSingleSelect,
    FeatherIcon,
    RejectModal,
    NSearchInput,
    NSearchContainer,
  },
  watch: {
    "params.companyId"(newValue, oldValue) {
      this.$refs.departmentId.clear();
    },
    perPage() {
      this.list();
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("userData")),
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {},
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
      },
      avatarText,
      getState,
      State,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        supervisorId: this.user.id,
      };

      OkrSettingRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "okr";
    const route = "okr";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
