<template>
  <!-- <section id="dashboard-analytics"></section> -->
    <dashboard></dashboard>
</template>

<script>
  import {
    BCol,
    BRow,
    BCard,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BCardText,
    BFormInput,
    BButton,
    BFormTextarea
  } from "bootstrap-vue";

import Dashboard from './dashboard/Dashboard.vue';
export default {
  components: {
      BFormInput,
      BCard,
      BCol,
      BRow,
      BFormRadioGroup,
      BFormRadio,
      BCardText,
      BFormGroup,
      BButton,
      BFormTextarea,
      Dashboard,
    },
};
</script>,
    Dashboard

<style>
</style>
