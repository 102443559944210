<template>
  <div>
    <b-card>
      <b-row>

        <b-col cols="12" class="d-flex justify-content-between flex-column">
          <!-- User Avatar & Action Buttons -->
          <div>
            <div>
              <profile-information :showButton="!user.canApprove" :userId="user.id"></profile-information>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
      <div class="table-card">
          <list></list>
      </div>
  </div>
</template>

<script>
import List from "./List.vue";
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BTable,
  BProgress,
  BBadge,
} from "bootstrap-vue";
import ProfileInformation from "@/components/ProfileInformation.vue";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import NImageView from "@/components/NImageView.vue";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BProgress,
    BBadge,
    ProfileInformation,
    FeatherIcon,
    List,
    NImageView,
  },
  data(){
    return {
      user: JSON.parse(localStorage.getItem("userData")),
    }
  },
  setup() {
    const resource = "okr";
    const route = "okr";

    return {
      resource,
      route,
    };
  },
};
</script>


