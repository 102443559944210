export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px",
  },
  {
    key: 'profile',
    label: 'field.image',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'image',
  },
  {
    key: 'name',
    sortable: false,
    sortField: 'name',
    label: 'field.name',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'idCard',
    label: 'field.idCard',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'company',
    label: 'field.company',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'department',
    label: 'field.department',
    thClass: 'text-left',
    tdClass: 'text-left',
  },

  {
    key: 'position',
    label: 'field.position',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'year',
    label: 'field.year',
    thClass: 'text-left',
    tdClass: 'text-left',
  },

  {
    key: 'state',
    label: 'field.okrSettingState',
  },
  {
    key: 'midYearState',
    label: 'field.midYearState',
  },

  {
    key: 'yearEndState',
    label: 'field.yearEndState',
  },


  {
    key: 'actions',
    label: 'field.action',
  },
];
