export default [
  {
    key: "companyId",
    label: "field.company",
    rules: "required",
    type: "nAsynSingleSelection",
    repository: "company",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "departmentId",
    label: "field.department",
    rules: "required",
    type: "nAsynSingleSelection",
    repository: "department",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "employeeId",
    label: "field.employee",
    rules: "required",
    type: "nAsynSingleSelection",
    repository: "employee",
    selectionKey: "id",
    selectionLabel: "nameLabel",
    clearable: true,
  },
  {
    key: "year",
    label: "field.year",
    type: "nYearPicker",
  },
];
